$ = jQuery;

class CategoriesTabs {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.tabs = this.root.querySelectorAll('[data-categories-tabs-id]');
        this.more = this.root.querySelector('[data-categories-tabs-more]');
        this.details = this.root.querySelector(
            '[data-categories-tabs-details]'
        );
        this.loader = this.root.querySelector('.preloader');

        this.html = this.details.innerHTML;
        this.currTabID = null;

        return true;
    }

    init() {
        this.initTabs();
    }

    initTabs() {
        if (!this.tabs) return;

        this.onTab = this.onTabClick.bind(this);
        this.tabs.forEach((item, index) =>
            item.addEventListener('click', event => this.onTab(event, index))
        );
    }

    onTabClick(event, index) {
        const { currentTarget } = event;
        const id = currentTarget.getAttribute('data-categories-tabs-id');
        this.currTabID = parseInt(id);

        this.setActiveTab(index);
        this.loader.classList.add('-active');
        this.getCategory();
    }

    getCategory() {
        const formData = new FormData();
        formData.append('action', 'get_category_details');

        if (this.currTabID) formData.append('cat_id', this.currTabID);

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html, cat_url } = data;

                this.html = html;
                this.details.innerHTML = this.html;
                this.more.href = cat_url;
            }.bind(this),
            error: function () {
                this.loader.classList.remove('-active');
            }.bind(this),
            complete: function () {
                this.loader.classList.remove('-active');
            }.bind(this),
        });
    }

    setActiveTab(index) {
        if (!this.tabs) return;

        this.tabs.forEach(item => item.classList.remove('active'));
        this.tabs[index].classList.add('active');
    }
}

const setCategoriesTabs = () => {
    const sections = document.querySelectorAll('[data-categories-tabs]');
    if (sections) sections.forEach(item => new CategoriesTabs(item));
};

setCategoriesTabs();
